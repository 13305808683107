import BasePlugin from '../BasePlugin'

export default class Export1CFiles extends BasePlugin {
  async execute () {
    let result = await this.context.$http.get(`${this.context.$config.api}/registryservice/plugins/execute/GetMainInformationAndSpecificationAndResultDataForExportQuery`)
    let mainInformationExport = await this.context.$http({
      method: 'post',
      url: `${this.context.$config.api}/etleditor/export/41`,
      responseType: 'arraybuffer',
      data: { 'ids': result.data.maininformation }
    })

    let specificationExport = await this.context.$http({
      method: 'post',
      url: `${this.context.$config.api}/etleditor/export/42`,
      responseType: 'arraybuffer',
      data: { 'ids': result.data.specification }
    })

    let applicationResultExport = await this.context.$http({
      method: 'post',
      url: `${this.context.$config.api}/etleditor/export/43`,
      responseType: 'arraybuffer',
      data: { 'ids': result.data.application_result }
    })

    let importLogRecordData = await this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/AddImportLogRecordCommand`)

    let mainInformationExportFile = new Blob([mainInformationExport.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    let specificationExportFile = new Blob([specificationExport.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    let applicationResultExportFile = new Blob([applicationResultExport.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

    let formDataMainInformation = new FormData()
    let formDataSpecifications = new FormData()
    let formDataResults = new FormData()

    formDataMainInformation.append('attr_2715_', mainInformationExportFile, 'mainInformation.xlsx')
    formDataSpecifications.append('attr_2715_', specificationExportFile, 'specifications.xlsx')
    formDataResults.append('attr_2715_', applicationResultExportFile, 'results.xlsx')

    formDataMainInformation.append('record_id', importLogRecordData.data.main_information_id)
    formDataMainInformation.append('registry_id', importLogRecordData.data.registry_id)

    formDataSpecifications.append('record_id', importLogRecordData.data.specification)
    formDataSpecifications.append('registry_id', importLogRecordData.data.registry_id)

    formDataResults.append('record_id', importLogRecordData.data.results)
    formDataResults.append('registry_id', importLogRecordData.data.registry_id)

    this.context.$http.post(`${this.context.$config.api}/registryservice/attachments`,
      formDataMainInformation, { headers: { 'Content-Type': 'multipart/form-data' } })

    this.context.$http.post(`${this.context.$config.api}/registryservice/attachments`,
      formDataSpecifications, { headers: { 'Content-Type': 'multipart/form-data' } })

    this.context.$http.post(`${this.context.$config.api}/registryservice/attachments`,
      formDataResults, { headers: { 'Content-Type': 'multipart/form-data' } })
  }
}
